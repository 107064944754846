























import {Vue, Component, Prop} from 'vue-property-decorator';
import {AddBOIReportPayload} from "@/dto/fincen/AddBOIReportPayload";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import PortalSelect from "@/components/common/PortalSelect.vue";
import {BOIReportType} from "@/constants/FinCENConstants";
import SelectOption, {OptionsBuilder} from "@/components/common/SelectOption";
import PortalDate from "@/components/common/PortalDate.vue";
import PortalCheckbox from "@/components/common/PortalCheckbox.vue";
import SingleFileHolder from "@/components/util/SingleFileHolder.vue";
import {namespace} from "vuex-class";
import {ifValid, processError} from "@/utils/ComponentUtils";
import FinCENService from "@/services/request/FinCENService";

const AppModule = namespace("App");

@Component({
  computed: {
    SelectOption() {
      return SelectOption
    },
    Opts() {
      return OptionsBuilder;
    },
    BRT() {
      return BOIReportType
    }
  },
  components: {SingleFileHolder, PortalCheckbox, PortalDate, PortalSelect, SimpleCompanySearch}
})
export default class AddBOIRequest extends Vue {

  @AppModule.Action  private startLoading!: () => void
  @AppModule.Action  private stopLoading!: () => void

  payload = new AddBOIReportPayload();

  @Prop()
  private correctedId?: number;

  @Prop()
  private companyId?: number;

  @Prop()
  private onSave!: () => void

  created(){
    this.payload.correctedReportId = this.correctedId;
    this.payload.companyId = this.companyId;
    if(this.correctedId){
      this.payload.type = BOIReportType.CORRECTED;
    }
  }

  companyChanged(company: CompanyDTO) {
    this.payload.companyId = company.id
  }

  get isCorrected(): boolean {
    return this.payload.correctedReportId != null;
  }

  get isInvoiceValid(): boolean {
    return !this.payload.paid || !!this.payload.invoiceFile;
  }

  get isReportValid(): boolean {
    return !!this.payload.reportFileId;
  }

  saveReport(){
    ifValid(this, () => {
      if(this.isInvoiceValid && this.isReportValid){
        this.startLoading();
        FinCENService.addBOIReport(this.payload).then(
            ok => {
              this.stopLoading();
              this.$modal.hideAll();
              this.onSave();
            },
            err => {
              this.stopLoading();
              processError(err, this);
            }
        )
      }
    })
  }

}
