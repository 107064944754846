































































import {Vue, Component} from 'vue-property-decorator';
import BOIReportDTO from "@/dto/fincen/BOIReportDTO";
import {namespace} from "vuex-class";
import FinCENService from "@/services/request/FinCENService";
import PaginationComponent from "@/components/util/PaginationComponent.vue";
import CompanyDTO from "@/dto/company/CompanyDTO";
import {processError} from "@/utils/ComponentUtils";
import SimpleCompanySearch from "@/components/common/SimpleCompanySearch.vue";
import AddBOIRequest from "@/components/documents/fincen/AddBOIRequest.vue";

const AppModule = namespace("App");

@Component({
  components: {SimpleCompanySearch, PaginationComponent}
})
export default class BOIReportList extends Vue {

  @AppModule.Action  private startLoading!: () => void
  @AppModule.Action  private stopLoading!: () => void

  reports: Array<BOIReportDTO> = [];
  totalPages = 1;
  pageNumber = 1;

  company?: CompanyDTO | null = null;

  mounted(){
    this.initReports();
  }

  initReports(){
    this.pageNumber = 1;
    this.totalPages = 1;
    this.company = null;
    this.loadReports();
  }

  loadReports(){
    this.startLoading();
    FinCENService.getBOIReports(this.pageNumber, 20, this.company?.id).then(
        ok => {
          this.reports = ok.data.content;
          this.pageNumber = ok.data.number + 1;
          this.totalPages = ok.data.totalPages;
          this.stopLoading();
        },
        err => {
          this.stopLoading();
          processError(err, this);
        }
    )
  }

  changePage(num: number){
    this.pageNumber = num;
    this.loadReports();
  }

  addNewReport(){
    this.$modal.show(
        AddBOIRequest,
        {
          onSave: this.initReports
        }
    )
  }

  addCorrectedReport(primaryReport: BOIReportDTO) {
    this.$modal.show(
        AddBOIRequest,
        {
          correctedId: primaryReport.id,
          companyId: primaryReport.companyId,
          onSave: this.initReports
        }
    )
  }

}
