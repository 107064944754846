import {BOIReportType} from "@/constants/FinCENConstants";
import FileMetaDTO from "@/dto/files/FileMetaDTO";

export class AddBOIReportPayload {
    companyId?: number;
    type?: BOIReportType;
    filingDate?: Date;
    filedByCustomer = false;
    paid = true;
    invoiceFile?: FileMetaDTO;
    reportFileId?: FileMetaDTO;
    correctedReportId?: number;
}